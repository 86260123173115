import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F5F5F5',
      neutralDark5: '#9A9FA8',
      neutralDark4: '#5F6269',
      neutralDark3: '#41454D',
      neutralDark2: '#33353B',
      neutralDark1: '#000000',
      primaryLight: '#F0E5DE',
      dangerLight: '#CC2121',
    },
  },
  fontFamily: {
    heading: "'Playfair Display', serif",
    paragraph: "'Work Sans', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
